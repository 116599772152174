<script>
import mixinsAlert from '@/components/common/mixinsAlert';

export default {
	name: 'SignUpAlready',
	mixins: [mixinsAlert],
	created() {
		this.$router.replace('/login');
		this.alert({
			contents: '이미 이메일 인증을 마친 사용자입니다. <br /> 로그인해주세요.</div>',
			buttons: [
				{
					text: '로그인',
				},
			],
		});
	},
};
</script>

<style scoped></style>
